import React from "react";
import { Row } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import CookbookSection from "../../components/cookbookSection";

const BreakfastCookbookSection = ({ location }) => {
  return (
    <CookbookSection
      location={location}
      sectionLink="breakfasts"
      sectionName="Breakfasts"
      sectionTitle="Breakfast"
      prevLink="/cookbook/beverages/"
      nextLink="/cookbook/dutch-oven-breakfasts/"
    >
      <Row className="d-flex pb-4 pt-4 flex-wrap justify-content-center" id="cookbook-top-text">
        <div className="ps-4 pe-4 pb-4 cookbook-top-section d-flex flex-column">
          <div>A great breakfast will give you the energy you need for a nice hike</div>
          <div className="pt-2">
            <StaticImage width={300} src="../../images/cookbook/frying-pan-bacon.jpg" alt="Frying pan with bacon" />
          </div>
        </div>
        <div className="ps-4 pe-4 pb-4 cookbook-top-section d-flex flex-column">
          <div className="flex-fill">... or just lounging around</div>
          <div className="pt-2">
            <StaticImage width={300} src="../../images/cookbook/van-sun.jpg" alt="Van with sunrise" />
          </div>
        </div>
      </Row>
    </CookbookSection>
  );
};

export default BreakfastCookbookSection;
